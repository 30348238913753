export const countries = [
  {
    value: 1004,
    label: "Afghanistan",
    currency_id: 201,
    capital_tz_id: 134,
    iso2: "AF",
    iso3: "AFG",
  },
  {
    value: 1248,
    label: "Aland Islands",
    currency_id: 249,
    capital_tz_id: 129,
    iso2: "AX",
    iso3: "ALA",
  },
  {
    value: 1008,
    label: "Albania",
    currency_id: 202,
    capital_tz_id: 127,
    iso2: "AL",
    iso3: "ALB",
  },
  {
    value: 1012,
    label: "Algeria",
    currency_id: 203,
    capital_tz_id: 127,
    iso2: "DZ",
    iso3: "DZA",
  },
  {
    value: 1016,
    label: "American Samoa",
    currency_id: 345,
    capital_tz_id: 103,
    iso2: "AS",
    iso3: "ASM",
  },
  {
    value: 1020,
    label: "Andorra",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "AD",
    iso3: "AND",
  },
  {
    value: 1024,
    label: "Angola",
    currency_id: 204,
    capital_tz_id: 127,
    iso2: "AO",
    iso3: "AGO",
  },
  {
    value: 1660,
    label: "Anguilla",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "AI",
    iso3: "AIA",
  },
  {
    value: 1028,
    label: "Antigua and Barbuda",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "AG",
    iso3: "ATG",
  },
  {
    value: 1032,
    label: "Argentina",
    currency_id: 206,
    capital_tz_id: 119,
    iso2: "AR",
    iso3: "ARG",
  },
  {
    value: 1051,
    label: "Armenia",
    currency_id: 207,
    capital_tz_id: 133,
    iso2: "AM",
    iso3: "ARM",
  },
  {
    value: 1533,
    label: "Aruba",
    currency_id: 208,
    capital_tz_id: 117,
    iso2: "AW",
    iso3: "ABW",
  },
  {
    value: 1036,
    label: "Australia",
    currency_id: 209,
    capital_tz_id: 148,
    iso2: "AU",
    iso3: "AUS",
  },
  {
    value: 1040,
    label: "Austria",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "AT",
    iso3: "AUT",
  },
  {
    value: 1031,
    label: "Azerbaijan",
    currency_id: 210,
    capital_tz_id: 133,
    iso2: "AZ",
    iso3: "AZE",
  },
  {
    value: 1044,
    label: "Bahamas",
    currency_id: 212,
    capital_tz_id: 115,
    iso2: "BS",
    iso3: "BHS",
  },
  {
    value: 1048,
    label: "Bahrain",
    currency_id: 213,
    capital_tz_id: 131,
    iso2: "BH",
    iso3: "BHR",
  },
  {
    value: 1050,
    label: "Bangladesh",
    currency_id: 214,
    capital_tz_id: 138,
    iso2: "BD",
    iso3: "BGD",
  },
  {
    value: 1052,
    label: "Barbados",
    currency_id: 215,
    capital_tz_id: 117,
    iso2: "BB",
    iso3: "BRB",
  },
  {
    value: 1112,
    label: "Belarus",
    currency_id: 297,
    capital_tz_id: 131,
    iso2: "BY",
    iso3: "BLR",
  },
  {
    value: 1056,
    label: "Belgium",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "BE",
    iso3: "BEL",
  },
  {
    value: 1084,
    label: "Belize",
    currency_id: 216,
    capital_tz_id: 113,
    iso2: "BZ",
    iso3: "BLZ",
  },
  {
    value: 1204,
    label: "Benin",
    currency_id: 351,
    capital_tz_id: 127,
    iso2: "BJ",
    iso3: "BEN",
  },
  {
    value: 1060,
    label: "Bermuda",
    currency_id: 217,
    capital_tz_id: 117,
    iso2: "BM",
    iso3: "BMU",
  },
  {
    value: 1535,
    label: "BES Islands (Caribbean)",
    currency_id: 345,
    capital_tz_id: 117,
    iso2: "BQ",
    iso3: "BES",
  },
  {
    value: 1064,
    label: "Bhutan",
    currency_id: 218,
    capital_tz_id: 138,
    iso2: "BT",
    iso3: "BTN",
  },
  {
    value: 1068,
    label: "Bolivia",
    currency_id: 219,
    capital_tz_id: 117,
    iso2: "BO",
    iso3: "BOL",
  },
  {
    value: 1070,
    label: "Bosnia and Herzegovina",
    currency_id: 211,
    capital_tz_id: 127,
    iso2: "BA",
    iso3: "BIH",
  },
  {
    value: 1072,
    label: "Botswana",
    currency_id: 220,
    capital_tz_id: 129,
    iso2: "BW",
    iso3: "BWA",
  },
  {
    value: 1076,
    label: "Brazil",
    currency_id: 221,
    capital_tz_id: 119,
    iso2: "BR",
    iso3: "BRA",
  },
  {
    value: 1096,
    label: "Brunei",
    currency_id: 223,
    capital_tz_id: 142,
    iso2: "BN",
    iso3: "BRN",
  },
  {
    value: 1100,
    label: "Bulgaria",
    currency_id: 224,
    capital_tz_id: 129,
    iso2: "BG",
    iso3: "BGR",
  },
  {
    value: 1854,
    label: "Burkina Faso",
    currency_id: 351,
    capital_tz_id: 125,
    iso2: "BF",
    iso3: "BFA",
  },
  {
    value: 1108,
    label: "Burundi",
    currency_id: 226,
    capital_tz_id: 129,
    iso2: "BI",
    iso3: "BDI",
  },
  {
    value: 1132,
    label: "Cabo Verde",
    currency_id: 229,
    capital_tz_id: 123,
    iso2: "CV",
    iso3: "CPV",
  },
  {
    value: 1116,
    label: "Cambodia",
    currency_id: 227,
    capital_tz_id: 140,
    iso2: "KH",
    iso3: "KHM",
  },
  {
    value: 1120,
    label: "Cameroon",
    currency_id: 231,
    capital_tz_id: 127,
    iso2: "CM",
    iso3: "CMR",
  },
  {
    value: 1124,
    label: "Canada",
    currency_id: 228,
    capital_tz_id: 115,
    iso2: "CA",
    iso3: "CAN",
  },
  {
    value: 1136,
    label: "Cayman Islands",
    currency_id: 230,
    capital_tz_id: 115,
    iso2: "KY",
    iso3: "CYM",
  },
  {
    value: 1140,
    label: "Central African Republic",
    currency_id: 231,
    capital_tz_id: 127,
    iso2: "CF",
    iso3: "CAF",
  },
  {
    value: 1148,
    label: "Chad",
    currency_id: 231,
    capital_tz_id: 127,
    iso2: "TD",
    iso3: "TCD",
  },
  {
    value: 1152,
    label: "Chile",
    currency_id: 233,
    capital_tz_id: 119,
    iso2: "CL",
    iso3: "CHL",
  },
  {
    value: 1156,
    label: "China",
    currency_id: 234,
    capital_tz_id: 142,
    iso2: "CN",
    iso3: "CHN",
  },
  {
    value: 1162,
    label: "Christmas Island",
    currency_id: 209,
    capital_tz_id: 140,
    iso2: "CX",
    iso3: "CXR",
  },
  {
    value: 1166,
    label: "Cocos Islands",
    currency_id: 209,
    capital_tz_id: 139,
    iso2: "CC",
    iso3: "CCK",
  },
  {
    value: 1170,
    label: "Colombia",
    currency_id: 235,
    capital_tz_id: 115,
    iso2: "CO",
    iso3: "COL",
  },
  {
    value: 1174,
    label: "Comoros",
    currency_id: 236,
    capital_tz_id: 131,
    iso2: "KM",
    iso3: "COM",
  },
  {
    value: 1184,
    label: "Cook Islands",
    currency_id: 299,
    capital_tz_id: 105,
    iso2: "CK",
    iso3: "COK",
  },
  {
    value: 1188,
    label: "Costa Rica",
    currency_id: 238,
    capital_tz_id: 113,
    iso2: "CR",
    iso3: "CRI",
  },
  {
    value: 1191,
    label: "Croatia",
    currency_id: 239,
    capital_tz_id: 127,
    iso2: "HR",
    iso3: "HRV",
  },
  {
    value: 1192,
    label: "Cuba",
    currency_id: 240,
    capital_tz_id: 115,
    iso2: "CU",
    iso3: "CUB",
  },
  {
    value: 1531,
    label: "Curacao",
    currency_id: 257,
    capital_tz_id: 117,
    iso2: "CW",
    iso3: "CUW",
  },
  {
    value: 1196,
    label: "Cyprus",
    currency_id: 249,
    capital_tz_id: 129,
    iso2: "CY",
    iso3: "CYP",
  },
  {
    value: 1203,
    label: "Czechia",
    currency_id: 241,
    capital_tz_id: 127,
    iso2: "CZ",
    iso3: "CZE",
  },
  {
    value: 1180,
    label: "Democratic Republic of Congo",
    currency_id: 237,
    capital_tz_id: 129,
    iso2: "CD",
    iso3: "COD",
  },
  {
    value: 1208,
    label: "Denmark",
    currency_id: 242,
    capital_tz_id: 127,
    iso2: "DK",
    iso3: "DNK",
  },
  {
    value: 1262,
    label: "Djibouti",
    currency_id: 243,
    capital_tz_id: 131,
    iso2: "DJ",
    iso3: "DJI",
  },
  {
    value: 1212,
    label: "Dominica",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "DM",
    iso3: "DMA",
  },
  {
    value: 1214,
    label: "Dominican Republic",
    currency_id: 244,
    capital_tz_id: 117,
    iso2: "DO",
    iso3: "DOM",
  },
  {
    value: 1218,
    label: "Ecuador",
    currency_id: 345,
    capital_tz_id: 115,
    iso2: "EC",
    iso3: "ECU",
  },
  {
    value: 1818,
    label: "Egypt",
    currency_id: 246,
    capital_tz_id: 129,
    iso2: "EG",
    iso3: "EGY",
  },
  {
    value: 1222,
    label: "El Salvador",
    currency_id: 345,
    capital_tz_id: 113,
    iso2: "SV",
    iso3: "SLV",
  },
  {
    value: 1226,
    label: "Equatorial Guinea",
    currency_id: 231,
    capital_tz_id: 127,
    iso2: "GQ",
    iso3: "GNQ",
  },
  {
    value: 1232,
    label: "Eritrea",
    currency_id: 247,
    capital_tz_id: 131,
    iso2: "ER",
    iso3: "ERI",
  },
  {
    value: 1233,
    label: "Estonia",
    currency_id: 249,
    capital_tz_id: 129,
    iso2: "EE",
    iso3: "EST",
  },
  {
    value: 1748,
    label: "Eswatini (Swaziland)",
    currency_id: 331,
    capital_tz_id: 129,
    iso2: "SZ",
    iso3: "SWZ",
  },
  {
    value: 1231,
    label: "Ethiopia",
    currency_id: 248,
    capital_tz_id: 131,
    iso2: "ET",
    iso3: "ETH",
  },
  {
    value: 1238,
    label: "Falkland Islands",
    currency_id: 250,
    capital_tz_id: 119,
    iso2: "FK",
    iso3: "FLK",
  },
  {
    value: 1234,
    label: "Faroe Islands",
    currency_id: 242,
    capital_tz_id: 125,
    iso2: "FO",
    iso3: "FRO",
  },
  {
    value: 1242,
    label: "Fiji",
    currency_id: 251,
    capital_tz_id: 152,
    iso2: "FJ",
    iso3: "FJI",
  },
  {
    value: 1246,
    label: "Finland",
    currency_id: 249,
    capital_tz_id: 129,
    iso2: "FI",
    iso3: "FIN",
  },
  {
    value: 1250,
    label: "France",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "FR",
    iso3: "FRA",
  },
  {
    value: 1254,
    label: "French Guiana",
    currency_id: 249,
    capital_tz_id: 119,
    iso2: "GF",
    iso3: "GUF",
  },
  {
    value: 1258,
    label: "French Polynesia",
    currency_id: 232,
    capital_tz_id: 105,
    iso2: "PF",
    iso3: "PYF",
  },
  {
    value: 1260,
    label: "French Southern Territories",
    currency_id: 249,
    capital_tz_id: 135,
    iso2: "TF",
    iso3: "ATF",
  },
  {
    value: 1266,
    label: "Gabon",
    currency_id: 231,
    capital_tz_id: 127,
    iso2: "GA",
    iso3: "GAB",
  },
  {
    value: 1270,
    label: "Gambia",
    currency_id: 252,
    capital_tz_id: 125,
    iso2: "GM",
    iso3: "GMB",
  },
  {
    value: 1268,
    label: "Georgia",
    currency_id: 253,
    capital_tz_id: 133,
    iso2: "GE",
    iso3: "GEO",
  },
  {
    value: 1276,
    label: "Germany",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "DE",
    iso3: "DEU",
  },
  {
    value: 1288,
    label: "Ghana",
    currency_id: 254,
    capital_tz_id: 125,
    iso2: "GH",
    iso3: "GHA",
  },
  {
    value: 1292,
    label: "Gibraltar",
    currency_id: 255,
    capital_tz_id: 127,
    iso2: "GI",
    iso3: "GIB",
  },
  {
    value: 1300,
    label: "Greece",
    currency_id: 249,
    capital_tz_id: 129,
    iso2: "GR",
    iso3: "GRC",
  },
  {
    value: 1304,
    label: "Greenland",
    currency_id: 242,
    capital_tz_id: 121,
    iso2: "GL",
    iso3: "GRL",
  },
  {
    value: 1308,
    label: "Grenada",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "GD",
    iso3: "GRD",
  },
  {
    value: 1312,
    label: "Guadeloupe",
    currency_id: 249,
    capital_tz_id: 117,
    iso2: "GP",
    iso3: "GLP",
  },
  {
    value: 1316,
    label: "Guam",
    currency_id: 345,
    capital_tz_id: 148,
    iso2: "GU",
    iso3: "GUM",
  },
  {
    value: 1320,
    label: "Guatemala",
    currency_id: 256,
    capital_tz_id: 113,
    iso2: "GT",
    iso3: "GTM",
  },
  {
    value: 1831,
    label: "Guernsey",
    currency_id: 222,
    capital_tz_id: 125,
    iso2: "GG",
    iso3: "GGY",
  },
  {
    value: 1324,
    label: "Guinea",
    currency_id: 258,
    capital_tz_id: 125,
    iso2: "GN",
    iso3: "GIN",
  },
  {
    value: 1624,
    label: "Guinea-Bissau",
    currency_id: 351,
    capital_tz_id: 125,
    iso2: "GW",
    iso3: "GNB",
  },
  {
    value: 1328,
    label: "Guyana",
    currency_id: 259,
    capital_tz_id: 117,
    iso2: "GY",
    iso3: "GUY",
  },
  {
    value: 1332,
    label: "Haiti",
    currency_id: 260,
    capital_tz_id: 115,
    iso2: "HT",
    iso3: "HTI",
  },
  {
    value: 1340,
    label: "Honduras",
    currency_id: 261,
    capital_tz_id: 113,
    iso2: "HN",
    iso3: "HND",
  },
  {
    value: 1344,
    label: "Hong Kong",
    currency_id: 262,
    capital_tz_id: 142,
    iso2: "HK",
    iso3: "HKG",
  },
  {
    value: 1348,
    label: "Hungary",
    currency_id: 263,
    capital_tz_id: 127,
    iso2: "HU",
    iso3: "HUN",
  },
  {
    value: 1352,
    label: "Iceland",
    currency_id: 264,
    capital_tz_id: 125,
    iso2: "IS",
    iso3: "ISL",
  },
  {
    value: 1356,
    label: "India",
    currency_id: 265,
    capital_tz_id: 136,
    iso2: "IN",
    iso3: "IND",
  },
  {
    value: 1360,
    label: "Indonesia",
    currency_id: 266,
    capital_tz_id: 140,
    iso2: "ID",
    iso3: "IDN",
  },
  {
    value: 1364,
    label: "Iran",
    currency_id: 267,
    capital_tz_id: 132,
    iso2: "IR",
    iso3: "IRN",
  },
  {
    value: 1368,
    label: "Iraq",
    currency_id: 268,
    capital_tz_id: 132,
    iso2: "IQ",
    iso3: "IRQ",
  },
  {
    value: 1372,
    label: "Ireland",
    currency_id: 249,
    capital_tz_id: 125,
    iso2: "IE",
    iso3: "IRL",
  },
  {
    value: 1833,
    label: "Isle of Man",
    currency_id: 222,
    capital_tz_id: 125,
    iso2: "IM",
    iso3: "IMN",
  },
  {
    value: 1376,
    label: "Israel",
    currency_id: 269,
    capital_tz_id: 129,
    iso2: "IL",
    iso3: "ISR",
  },
  {
    value: 1380,
    label: "Italy",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "IT",
    iso3: "ITA",
  },
  {
    value: 1384,
    label: "Ivory Coast",
    currency_id: 351,
    capital_tz_id: 125,
    iso2: "CI",
    iso3: "CIV",
  },
  {
    value: 1388,
    label: "Jamaica",
    currency_id: 270,
    capital_tz_id: 115,
    iso2: "JM",
    iso3: "JAM",
  },
  {
    value: 1392,
    label: "Japan",
    currency_id: 271,
    capital_tz_id: 145,
    iso2: "JP",
    iso3: "JPN",
  },
  {
    value: 1832,
    label: "Jersey",
    currency_id: 222,
    capital_tz_id: 125,
    iso2: "JE",
    iso3: "JEY",
  },
  {
    value: 1400,
    label: "Jordan",
    currency_id: 272,
    capital_tz_id: 129,
    iso2: "JO",
    iso3: "JOR",
  },
  {
    value: 1398,
    label: "Kazakhstan",
    currency_id: 273,
    capital_tz_id: 135,
    iso2: "KZ",
    iso3: "KAZ",
  },
  {
    value: 1404,
    label: "Kenya",
    currency_id: 274,
    capital_tz_id: 131,
    iso2: "KE",
    iso3: "KEN",
  },
  {
    value: 1296,
    label: "Kiribati",
    currency_id: 209,
    capital_tz_id: 152,
    iso2: "KI",
    iso3: "KIR",
  },
  {
    value: 1926,
    label: "Kosovo",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "XK",
    iso3: "XKX",
  },
  {
    value: 1414,
    label: "Kuwait",
    currency_id: 275,
    capital_tz_id: 131,
    iso2: "KW",
    iso3: "KWT",
  },
  {
    value: 1417,
    label: "Kyrgyzstan",
    currency_id: 276,
    capital_tz_id: 138,
    iso2: "KG",
    iso3: "KGZ",
  },
  {
    value: 1418,
    label: "Laos",
    currency_id: 277,
    capital_tz_id: 140,
    iso2: "LA",
    iso3: "LAO",
  },
  {
    value: 1428,
    label: "Latvia",
    currency_id: 249,
    capital_tz_id: 129,
    iso2: "LV",
    iso3: "LVA",
  },
  {
    value: 1422,
    label: "Lebanon",
    currency_id: 278,
    capital_tz_id: 129,
    iso2: "LB",
    iso3: "LBN",
  },
  {
    value: 1426,
    label: "Lesotho",
    currency_id: 279,
    capital_tz_id: 129,
    iso2: "LS",
    iso3: "LSO",
  },
  {
    value: 1430,
    label: "Liberia",
    currency_id: 280,
    capital_tz_id: 125,
    iso2: "LR",
    iso3: "LBR",
  },
  {
    value: 1434,
    label: "Libya",
    currency_id: 281,
    capital_tz_id: 129,
    iso2: "LY",
    iso3: "LBY",
  },
  {
    value: 1438,
    label: "Liechtenstein",
    currency_id: 333,
    capital_tz_id: 127,
    iso2: "LI",
    iso3: "LIE",
  },
  {
    value: 1440,
    label: "Lithuania",
    currency_id: 249,
    capital_tz_id: 129,
    iso2: "LT",
    iso3: "LTU",
  },
  {
    value: 1442,
    label: "Luxembourg",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "LU",
    iso3: "LUX",
  },
  {
    value: 1446,
    label: "Macao",
    currency_id: 282,
    capital_tz_id: 142,
    iso2: "MO",
    iso3: "MAC",
  },
  {
    value: 1450,
    label: "Madagascar",
    currency_id: 284,
    capital_tz_id: 131,
    iso2: "MG",
    iso3: "MDG",
  },
  {
    value: 1454,
    label: "Malawi",
    currency_id: 285,
    capital_tz_id: 129,
    iso2: "MW",
    iso3: "MWI",
  },
  {
    value: 1458,
    label: "Malaysia",
    currency_id: 286,
    capital_tz_id: 142,
    iso2: "MY",
    iso3: "MYS",
  },
  {
    value: 1462,
    label: "Maldives",
    currency_id: 287,
    capital_tz_id: 135,
    iso2: "MV",
    iso3: "MDV",
  },
  {
    value: 1466,
    label: "Mali",
    currency_id: 351,
    capital_tz_id: 125,
    iso2: "ML",
    iso3: "MLI",
  },
  {
    value: 1470,
    label: "Malta",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "MT",
    iso3: "MLT",
  },
  {
    value: 1584,
    label: "Marshall Islands",
    currency_id: 345,
    capital_tz_id: 152,
    iso2: "MH",
    iso3: "MHL",
  },
  {
    value: 1474,
    label: "Martinique",
    currency_id: 249,
    capital_tz_id: 117,
    iso2: "MQ",
    iso3: "MTQ",
  },
  {
    value: 1478,
    label: "Mauritania",
    currency_id: 288,
    capital_tz_id: 125,
    iso2: "MR",
    iso3: "MRT",
  },
  {
    value: 1480,
    label: "Mauritius",
    currency_id: 289,
    capital_tz_id: 133,
    iso2: "MU",
    iso3: "MUS",
  },
  {
    value: 1175,
    label: "Mayotte",
    currency_id: 249,
    capital_tz_id: 131,
    iso2: "YT",
    iso3: "MYT",
  },
  {
    value: 1484,
    label: "Mexico",
    currency_id: 290,
    capital_tz_id: 113,
    iso2: "MX",
    iso3: "MEX",
  },
  {
    value: 1583,
    label: "Micronesia",
    currency_id: 345,
    capital_tz_id: 150,
    iso2: "FM",
    iso3: "FSM",
  },
  {
    value: 1498,
    label: "Moldova",
    currency_id: 291,
    capital_tz_id: 129,
    iso2: "MD",
    iso3: "MDA",
  },
  {
    value: 1492,
    label: "Monaco",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "MC",
    iso3: "MCO",
  },
  {
    value: 1496,
    label: "Mongolia",
    currency_id: 292,
    capital_tz_id: 142,
    iso2: "MN",
    iso3: "MNG",
  },
  {
    value: 1499,
    label: "Montenegro",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "ME",
    iso3: "MNE",
  },
  {
    value: 1500,
    label: "Montserrat",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "MS",
    iso3: "MSR",
  },
  {
    value: 1504,
    label: "Morocco",
    currency_id: 293,
    capital_tz_id: 127,
    iso2: "MA",
    iso3: "MAR",
  },
  {
    value: 1508,
    label: "Mozambique",
    currency_id: 294,
    capital_tz_id: 129,
    iso2: "MZ",
    iso3: "MOZ",
  },
  {
    value: 1104,
    label: "Myanmar",
    currency_id: 225,
    capital_tz_id: 139,
    iso2: "MM",
    iso3: "MMR",
  },
  {
    value: 1516,
    label: "Namibia",
    currency_id: 295,
    capital_tz_id: 129,
    iso2: "NA",
    iso3: "NAM",
  },
  {
    value: 1520,
    label: "Nauru",
    currency_id: 209,
    capital_tz_id: 152,
    iso2: "NR",
    iso3: "NRU",
  },
  {
    value: 1524,
    label: "Nepal",
    currency_id: 296,
    capital_tz_id: 137,
    iso2: "NP",
    iso3: "NPL",
  },
  {
    value: 1528,
    label: "Netherlands",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "NL",
    iso3: "NLD",
  },
  {
    value: 1530,
    label: "Netherlands Antilles",
    currency_id: 257,
    capital_tz_id: 117,
    iso2: "AN",
    iso3: "ANT",
  },
  {
    value: 1540,
    label: "New Caledonia",
    currency_id: 232,
    capital_tz_id: 150,
    iso2: "NC",
    iso3: "NCL",
  },
  {
    value: 1554,
    label: "New Zealand",
    currency_id: 299,
    capital_tz_id: 152,
    iso2: "NZ",
    iso3: "NZL",
  },
  {
    value: 1558,
    label: "Nicaragua",
    currency_id: 300,
    capital_tz_id: 113,
    iso2: "NI",
    iso3: "NIC",
  },
  {
    value: 1562,
    label: "Niger",
    currency_id: 351,
    capital_tz_id: 127,
    iso2: "NE",
    iso3: "NER",
  },
  {
    value: 1566,
    label: "Nigeria",
    currency_id: 301,
    capital_tz_id: 127,
    iso2: "NG",
    iso3: "NGA",
  },
  {
    value: 1570,
    label: "Niue",
    currency_id: 299,
    capital_tz_id: 103,
    iso2: "NU",
    iso3: "NIU",
  },
  {
    value: 1574,
    label: "Norfolk Island",
    currency_id: 209,
    capital_tz_id: 150,
    iso2: "NF",
    iso3: "NFK",
  },
  {
    value: 1408,
    label: "North Korea",
    currency_id: 302,
    capital_tz_id: 145,
    iso2: "KP",
    iso3: "PRK",
  },
  {
    value: 1807,
    label: "North Macedonia",
    currency_id: 283,
    capital_tz_id: 127,
    iso2: "MK",
    iso3: "MKD",
  },
  {
    value: 1580,
    label: "Northern Mariana Islands",
    currency_id: 345,
    capital_tz_id: 148,
    iso2: "MP",
    iso3: "MNP",
  },
  {
    value: 1578,
    label: "Norway",
    currency_id: 303,
    capital_tz_id: 127,
    iso2: "NO",
    iso3: "NOR",
  },
  {
    value: 1512,
    label: "Oman",
    currency_id: 304,
    capital_tz_id: 133,
    iso2: "OM",
    iso3: "OMN",
  },
  {
    value: 1586,
    label: "Pakistan",
    currency_id: 305,
    capital_tz_id: 135,
    iso2: "PK",
    iso3: "PAK",
  },
  {
    value: 1585,
    label: "Palau",
    currency_id: 345,
    capital_tz_id: 145,
    iso2: "PW",
    iso3: "PLW",
  },
  {
    value: 1275,
    label: "Palestinian Territory",
    currency_id: 269,
    capital_tz_id: 129,
    iso2: "PS",
    iso3: "PSE",
  },
  {
    value: 1591,
    label: "Panama",
    currency_id: 345,
    capital_tz_id: 115,
    iso2: "PA",
    iso3: "PAN",
  },
  {
    value: 1598,
    label: "Papua New Guinea",
    currency_id: 306,
    capital_tz_id: 148,
    iso2: "PG",
    iso3: "PNG",
  },
  {
    value: 1600,
    label: "Paraguay",
    currency_id: 307,
    capital_tz_id: 117,
    iso2: "PY",
    iso3: "PRY",
  },
  {
    value: 1604,
    label: "Peru",
    currency_id: 308,
    capital_tz_id: 115,
    iso2: "PE",
    iso3: "PER",
  },
  {
    value: 1608,
    label: "Philippines",
    currency_id: 309,
    capital_tz_id: 142,
    iso2: "PH",
    iso3: "PHL",
  },
  {
    value: 1616,
    label: "Poland",
    currency_id: 310,
    capital_tz_id: 127,
    iso2: "PL",
    iso3: "POL",
  },
  {
    value: 1620,
    label: "Portugal",
    currency_id: 249,
    capital_tz_id: 125,
    iso2: "PT",
    iso3: "PRT",
  },
  {
    value: 1630,
    label: "Puerto Rico",
    currency_id: 345,
    capital_tz_id: 117,
    iso2: "PR",
    iso3: "PRI",
  },
  {
    value: 1634,
    label: "Qatar",
    currency_id: 311,
    capital_tz_id: 131,
    iso2: "QA",
    iso3: "QAT",
  },
  {
    value: 1178,
    label: "Republic of Congo",
    currency_id: 231,
    capital_tz_id: 127,
    iso2: "CG",
    iso3: "COG",
  },
  {
    value: 1638,
    label: "Reunion",
    currency_id: 249,
    capital_tz_id: 133,
    iso2: "RE",
    iso3: "REU",
  },
  {
    value: 1642,
    label: "Romania",
    currency_id: 312,
    capital_tz_id: 129,
    iso2: "RO",
    iso3: "ROU",
  },
  {
    value: 1643,
    label: "Russia",
    currency_id: 313,
    capital_tz_id: 131,
    iso2: "RU",
    iso3: "RUS",
  },
  {
    value: 1646,
    label: "Rwanda",
    currency_id: 314,
    capital_tz_id: 129,
    iso2: "RW",
    iso3: "RWA",
  },
  {
    value: 1652,
    label: "Saint Barthelemy",
    currency_id: 249,
    capital_tz_id: 117,
    iso2: "BL",
    iso3: "BLM",
  },
  {
    value: 1654,
    label: "Saint Helena",
    currency_id: 315,
    capital_tz_id: 125,
    iso2: "SH",
    iso3: "SHN",
  },
  {
    value: 1659,
    label: "Saint Kitts and Nevis",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "KN",
    iso3: "KNA",
  },
  {
    value: 1662,
    label: "Saint Lucia",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "LC",
    iso3: "LCA",
  },
  {
    value: 1663,
    label: "Saint Martin",
    currency_id: 249,
    capital_tz_id: 117,
    iso2: "MF",
    iso3: "MAF",
  },
  {
    value: 1666,
    label: "Saint Pierre and Miquelon",
    currency_id: 249,
    capital_tz_id: 119,
    iso2: "PM",
    iso3: "SPM",
  },
  {
    value: 1670,
    label: "Saint Vincent and Grenadines",
    currency_id: 245,
    capital_tz_id: 117,
    iso2: "VC",
    iso3: "VCT",
  },
  {
    value: 1882,
    label: "Samoa",
    currency_id: 316,
    capital_tz_id: 155,
    iso2: "WS",
    iso3: "WSM",
  },
  {
    value: 1674,
    label: "San Marino",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "SM",
    iso3: "SMR",
  },
  {
    value: 1678,
    label: "Sao Tome and Principe",
    currency_id: 317,
    capital_tz_id: 125,
    iso2: "ST",
    iso3: "STP",
  },
  {
    value: 1682,
    label: "Saudi Arabia",
    currency_id: 318,
    capital_tz_id: 131,
    iso2: "SA",
    iso3: "SAU",
  },
  {
    value: 1686,
    label: "Senegal",
    currency_id: 351,
    capital_tz_id: 125,
    iso2: "SN",
    iso3: "SEN",
  },
  {
    value: 1688,
    label: "Serbia",
    currency_id: 319,
    capital_tz_id: 127,
    iso2: "RS",
    iso3: "SRB",
  },
  {
    value: 1690,
    label: "Seychelles",
    currency_id: 320,
    capital_tz_id: 133,
    iso2: "SC",
    iso3: "SYC",
  },
  {
    value: 1694,
    label: "Sierra Leone",
    currency_id: 321,
    capital_tz_id: 125,
    iso2: "SL",
    iso3: "SLE",
  },
  {
    value: 1702,
    label: "Singapore",
    currency_id: 322,
    capital_tz_id: 142,
    iso2: "SG",
    iso3: "SGP",
  },
  {
    value: 1534,
    label: "Sint Maarten",
    currency_id: 257,
    capital_tz_id: 117,
    iso2: "SX",
    iso3: "SXM",
  },
  {
    value: 1703,
    label: "Slovakia",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "SK",
    iso3: "SVK",
  },
  {
    value: 1705,
    label: "Slovenia",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "SI",
    iso3: "SVN",
  },
  {
    value: 1090,
    label: "Solomon Islands",
    currency_id: 323,
    capital_tz_id: 150,
    iso2: "SB",
    iso3: "SLB",
  },
  {
    value: 1706,
    label: "Somalia",
    currency_id: 324,
    capital_tz_id: 131,
    iso2: "SO",
    iso3: "SOM",
  },
  {
    value: 1710,
    label: "South Africa",
    currency_id: 325,
    capital_tz_id: 129,
    iso2: "ZA",
    iso3: "ZAF",
  },
  {
    value: 1410,
    label: "South Korea",
    currency_id: 326,
    capital_tz_id: 145,
    iso2: "KR",
    iso3: "KOR",
  },
  {
    value: 1728,
    label: "South Sudan",
    currency_id: 327,
    capital_tz_id: 129,
    iso2: "SS",
    iso3: "SSD",
  },
  {
    value: 1724,
    label: "Spain",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "ES",
    iso3: "ESP",
  },
  {
    value: 1144,
    label: "Sri Lanka",
    currency_id: 328,
    capital_tz_id: 136,
    iso2: "LK",
    iso3: "LKA",
  },
  {
    value: 1729,
    label: "Sudan",
    currency_id: 329,
    capital_tz_id: 129,
    iso2: "SD",
    iso3: "SDN",
  },
  {
    value: 1740,
    label: "Suriname",
    currency_id: 330,
    capital_tz_id: 119,
    iso2: "SR",
    iso3: "SUR",
  },
  {
    value: 1744,
    label: "Svalbard and Jan Mayen",
    currency_id: 303,
    capital_tz_id: 127,
    iso2: "SJ",
    iso3: "SJM",
  },
  {
    value: 1752,
    label: "Sweden",
    currency_id: 332,
    capital_tz_id: 127,
    iso2: "SE",
    iso3: "SWE",
  },
  {
    value: 1756,
    label: "Switzerland",
    currency_id: 333,
    capital_tz_id: 127,
    iso2: "CH",
    iso3: "CHE",
  },
  {
    value: 1760,
    label: "Syria",
    currency_id: 334,
    capital_tz_id: 129,
    iso2: "SY",
    iso3: "SYR",
  },
  {
    value: 1158,
    label: "Taiwan",
    currency_id: 298,
    capital_tz_id: 142,
    iso2: "TW",
    iso3: "TWN",
  },
  {
    value: 1762,
    label: "Tajikistan",
    currency_id: 335,
    capital_tz_id: 135,
    iso2: "TJ",
    iso3: "TJK",
  },
  {
    value: 1834,
    label: "Tanzania",
    currency_id: 336,
    capital_tz_id: 131,
    iso2: "TZ",
    iso3: "TZA",
  },
  {
    value: 1764,
    label: "Thailand",
    currency_id: 337,
    capital_tz_id: 140,
    iso2: "TH",
    iso3: "THA",
  },
  {
    value: 1626,
    label: "Timor Leste",
    currency_id: 345,
    capital_tz_id: 145,
    iso2: "TL",
    iso3: "TLS",
  },
  {
    value: 1768,
    label: "Togo",
    currency_id: 351,
    capital_tz_id: 125,
    iso2: "TG",
    iso3: "TGO",
  },
  {
    value: 1772,
    label: "Tokelau",
    currency_id: 299,
    capital_tz_id: 155,
    iso2: "TK",
    iso3: "TKL",
  },
  {
    value: 1776,
    label: "Tonga",
    currency_id: 338,
    capital_tz_id: 155,
    iso2: "TO",
    iso3: "TON",
  },
  {
    value: 1780,
    label: "Trinidad and Tobago",
    currency_id: 339,
    capital_tz_id: 117,
    iso2: "TT",
    iso3: "TTO",
  },
  {
    value: 1788,
    label: "Tunisia",
    currency_id: 340,
    capital_tz_id: 127,
    iso2: "TN",
    iso3: "TUN",
  },
  {
    value: 1792,
    label: "Turkey",
    currency_id: 341,
    capital_tz_id: 131,
    iso2: "TR",
    iso3: "TUR",
  },
  {
    value: 1795,
    label: "Turkmenistan",
    currency_id: 342,
    capital_tz_id: 135,
    iso2: "TM",
    iso3: "TKM",
  },
  {
    value: 1796,
    label: "Turks and Caicos Islands",
    currency_id: 345,
    capital_tz_id: 117,
    iso2: "TC",
    iso3: "TCA",
  },
  {
    value: 1798,
    label: "Tuvalu",
    currency_id: 209,
    capital_tz_id: 152,
    iso2: "TV",
    iso3: "TUV",
  },
  {
    value: 1800,
    label: "Uganda",
    currency_id: 343,
    capital_tz_id: 131,
    iso2: "UG",
    iso3: "UGA",
  },
  {
    value: 1804,
    label: "Ukraine",
    currency_id: 344,
    capital_tz_id: 129,
    iso2: "UA",
    iso3: "UKR",
  },
  {
    value: 1784,
    label: "United Arab Emirates",
    currency_id: 205,
    capital_tz_id: 133,
    iso2: "AE",
    iso3: "UAE/ARE",
  },
  {
    value: 1826,
    label: "United Kingdom",
    currency_id: 222,
    capital_tz_id: 125,
    iso2: "GB",
    iso3: "UK/GBR",
  },
  {
    value: 1840,
    label: "United States",
    currency_id: 345,
    capital_tz_id: 115,
    iso2: "US",
    iso3: "USA",
  },
  {
    value: 1858,
    label: "Uruguay",
    currency_id: 346,
    capital_tz_id: 119,
    iso2: "UY",
    iso3: "URY",
  },
  {
    value: 1860,
    label: "Uzbekistan",
    currency_id: 347,
    capital_tz_id: 135,
    iso2: "UZ",
    iso3: "UZB",
  },
  {
    value: 1548,
    label: "Vanuatu",
    currency_id: 348,
    capital_tz_id: 150,
    iso2: "VU",
    iso3: "VUT",
  },
  {
    value: 1336,
    label: "Vatican (Holy See)",
    currency_id: 249,
    capital_tz_id: 127,
    iso2: "VA",
    iso3: "VAT",
  },
  {
    value: 1862,
    label: "Venezuela",
    currency_id: 349,
    capital_tz_id: 117,
    iso2: "VE",
    iso3: "VEN",
  },
  {
    value: 1704,
    label: "Vietnam",
    currency_id: 350,
    capital_tz_id: 140,
    iso2: "VN",
    iso3: "VNM",
  },
  {
    value: 1092,
    label: "Virgin Islands (British)",
    currency_id: 345,
    capital_tz_id: 117,
    iso2: "VG",
    iso3: "VGB",
  },
  {
    value: 1850,
    label: "Virgin Islands (of US)",
    currency_id: 345,
    capital_tz_id: 117,
    iso2: "VI",
    iso3: "VIR",
  },
  {
    value: 1876,
    label: "Wallis and Futuna",
    currency_id: 232,
    capital_tz_id: 152,
    iso2: "WF",
    iso3: "WLF",
  },
  {
    value: 1732,
    label: "Western Sahara",
    currency_id: 293,
    capital_tz_id: 125,
    iso2: "EH",
    iso3: "ESH",
  },
  {
    value: 1887,
    label: "Yemen",
    currency_id: 352,
    capital_tz_id: 131,
    iso2: "YE",
    iso3: "YEM",
  },
  {
    value: 1894,
    label: "Zambia",
    currency_id: 353,
    capital_tz_id: 129,
    iso2: "ZM",
    iso3: "ZMB",
  },
  {
    value: 1716,
    label: "Zimbabwe",
    currency_id: 345,
    capital_tz_id: 129,
    iso2: "ZW",
    iso3: "ZWE",
  },
];
